import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import BugReportIcon from "@mui/icons-material/BugReport";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import {
  DebugOptionsSelector,
  DebugSpeedDial,
  DebugSpeedDialAction,
} from "@plansoft-configuration-renderer/components";
import {
  ConfigurationController,
  ConfigurationInitializer,
  ConfigurationRenderer,
  HeartbeatController,
} from "@plansoft-configuration-renderer/framework";
import { SettingsSync } from "@plansoft-configuration-renderer/layout";
import { useBackendTranslations } from "@plansoft-configuration-renderer/localization";

import ConfigurationHeader from "@/components/ConfigurationHeader";

import { ConfigurationStateSpy } from "./ConfigurationStateSpy";
import { CustomRenderer } from "./CustomRenderer";
import "./MinimalRenderer.css";
import CustomCadClickViewer3DController from "./components/CustomCadClickViewer3DController";

export interface MinimalRendererProps {
  /**
   * Configuration object id
   */
  cfgObjectId: string;
  /**
   * Configuration language / locale code
   */
  language: string;
  /**
   * The base URL must be mapped by the proxy
   */
  baseUrl: string;
  /**
   * Use a specific layout, only works for the root cfg object
   */
  layoutId?: string;
}

/**
 * This is the base setup for the renderer
 *
 * - the `CsCfgObjectController` starts the configuration
 * - the `ConfigurationController` provides the necessary state management
 */
export function MinimalRenderer(props: MinimalRendererProps): JSX.Element {
  // initializes the translations
  const isReady = useBackendTranslations({
    basePath: props.baseUrl,
    localeId: props.language,
  });

  // see setupProxy.js in src folder
  // in production this could be loaded from the database et
  const { baseUrl, ...startOptions } = props;

  // if any start option changes, reset the ConfigurationController
  const configurationResetKey = Object.values(props).join("#");

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  if (!isReady) {
    return <Fragment />;
  }

  return (
    <ConfigurationController baseUrl={baseUrl} key={configurationResetKey}>
      {/* ConfigurationStarter starts to configuration but renders nothing. Also see ConfigurationResumer */}
      <ConfigurationInitializer
        cfgObjectId={startOptions.cfgObjectId}
        localeId={startOptions.language}
      />
      {/* ConfigurationRenderer prevents the configuration from rendering when there is not cfg object yet */}
      <ConfigurationRenderer>
        {/* Renders nothing, but keeps the session alive */}
        <HeartbeatController interval={60e3} />
        {/* Sync settings to redux store, omit if not needed */}
        <SettingsSync />
        {/* Display loading spinner when requests are open */}
        <Box className="layout-container">
          <ConfigurationHeader />

          {/* Renders the configuration */}
          <CustomRenderer />
        </Box>
        {/* Spy on configuration state changes */}
        <Box position="fixed" right="20px" bottom="20px">
          <DebugSpeedDial
            icon={<BugReportIcon />}
            defaultIsOpen={false}
            FabProps={{
              sx: {
                backgroundColor: "warning.main",
              },
            }}
          >
            <DebugSpeedDialAction
              icon={<BugReportIcon />}
              tooltipTitle={t("show_debug_drawer")}
              onClick={() => setIsOpen(!isOpen)}
            />
          </DebugSpeedDial>
        </Box>
        <Drawer
          variant="temporary"
          anchor="right"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <DebugOptionsSelector />
        </Drawer>
        <ConfigurationStateSpy />
      </ConfigurationRenderer>
      <CustomCadClickViewer3DController />
    </ConfigurationController>
  );
}
